import { Box, Card, CardBody, Flex, Text, Icon, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IoChevronForwardOutline } from "react-icons/io5";
import api from "../servicos/Api";

const Notificacoes = () => {

    const [notificacoes, setNotificacoes] = useState([]);
    const [notificacao, setNotificacao] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        getNotificacoes();
    }, []);

    const getNotificacoes = async () => {
        const response = await api.post('/login_app/get_notificacoes');
        if(response.data.error === false){
            setNotificacoes(response.data.data);
        }
    };

    const CardNotificacao = (props) => {
        return(<Card key={'noti'+props.n.seq_notificacao} mb='1' onClick={() => {setNotificacao(props.n); setIsOpen(true);}}>
                <CardBody>
                    <Flex justifyContent='space-between' alignItems='center'>
                    <Flex direction='column'>
                        <Text lineHeight='1.2'>{props.n.titulo}</Text>
                        <Text fontSize='sm' >{props.n.dt_criacao_format}</Text>
                    </Flex>
                    <Icon as={IoChevronForwardOutline} boxSize='5'/>
                    </Flex>
                </CardBody>
            </Card>);
    };

    return(
        <>
        <Box p='4'>
            {notificacoes.map(n => <CardNotificacao n={n}/>)}
        </Box>
        <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={() => setIsOpen(false)}
            size='md'
        >
            <DrawerOverlay />
            <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Notificação</DrawerHeader>

            <DrawerBody>
                {notificacao ? <>
                <Text fontWeight='bold' lineHeight='1.2'>{notificacao.titulo}</Text>
                <Text mb='6' fontSize='sm'>{notificacao.dt_criacao_format}</Text>
                <Text>{notificacao.corpo}</Text>
                </> : ''}
            </DrawerBody>
            </DrawerContent>
        </Drawer>
        </>
    );
};

export default Notificacoes;