import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import MainContainer from './container/MainContainer';
import { onMessageListener } from './Firebase';
import {
  HashRouter as Router
} from "react-router-dom";

import './App.css';

onMessageListener().then(payload => {
  console.log(payload.notification.title);
  console.log(payload.notification.body); 
}).catch(err => 
    console.log('failed: ', err)
);

function App() {
  return (
    <ChakraProvider>
      <Router>
        <MainContainer/>
      </Router>
    </ChakraProvider>
  );
}

export default App;
