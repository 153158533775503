import { Card, CardBody, Text, Box, Flex, Icon, Textarea, Button, Drawer, DrawerOverlay, 
        DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IoBanOutline, IoCheckmarkOutline, IoChevronForwardOutline, IoCloseCircleOutline, IoAttach, IoCloudDownloadOutline } from "react-icons/io5";
import api from "../servicos/Api";
import { useNavigate } from "react-router-dom";

const Reunioes = () => {
    var navigate = useNavigate();
    const [reunioes, setReunioes] = useState([]);
    const [reuniao, setReuniao] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [justificativa, setJustificativa] = useState('');
    const [aguarde, setAguarde] = useState(false);
    const [Documentos, setDocumentos] = useState(null);
    const [isDrawerDocumentoOpen, setDrawerDocumentoOpen] = useState(false);    

    useEffect(() => {
        getReunioes();
        validaUser();
    }, []);

    const getReunioes = async () => {
        const response = await api.get('/login_app/reunioes/0');
        if(response.data.error === false){
            setReunioes(response.data.data);
        }
    };
    
    const downloadDoc = async (seq_conselho_reuniao_documento) => {
        //window.open('http://localhost:8080/api/index.php/login/setToken/'+ localStorage.getItem('dt')+'/'+ seq_conselho_reuniao_documento);
        //window.open('https://dev.conselho.club/api/index.php/login/setToken/'+ localStorage.getItem('dt')+'/'+seq_conselho_reuniao_documento);
        window.open('https://cd.internacional.com.br/api/index.php/login/setToken/'+ localStorage.getItem('dt')+'/'+seq_conselho_reuniao_documento);
    };

    const documentos = async (seq_reuniao) => {
        const response = await api.get("/login_app/documentos/"+ seq_reuniao);
        if(response.data.error === false){
            setDocumentos(response.data.data);
        }
        setDrawerDocumentoOpen(true);
    }

    const validaUser = async () => {
        const response = await api.get('login_app/valida_user');
        if(response.data.valido != "t")
        {
            api.post(
                '/login_app/remove_all_topicos', 
                {
                    matricula: localStorage.getItem('matricula')
                    , token: localStorage.getItem('ac')
                }
            );
            localStorage.setItem('ac', null); 
            
            navigate('/login');
        }
    };

    const DrawerDomento = (props) => {
        if(props.documentos){
        return (
        <Drawer isOpen={isDrawerDocumentoOpen} placement='right' onClose={() => setDrawerDocumentoOpen(false)} size='lg'>
            <DrawerOverlay />
            <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Documentos da Reunião {props.documentos[0].titulo}</DrawerHeader>

            <DrawerBody>
                <>
                <Flex direction='column' justifyContent='left' alignItems='left'>    
                    {props.documentos.map(doc => {
                        return (
                            <Card mb='2' onClick={()=>{downloadDoc(doc.seq_conselho_reuniao_documento)}}>
                                <CardBody>
                                    <Flex justifyContent='space-between' alignItems='center'>
                                        <Flex alignItems='center'>
                                            <Text ml='4' fontWeight='700'>{doc.nome}</Text>
                                        </Flex>                        
                                        <Icon boxSize='6' color='red' as={IoCloudDownloadOutline}/>
                                    </Flex>
                                </CardBody>
                            </Card>
                        )                        
                    })}

                </Flex>
                </> 
            </DrawerBody>
            </DrawerContent>
        </Drawer> 
        ) } else {
            return (<></>)
        }
    };

    const justificar = async () => {
        if(justificativa !== ''){
            setAguarde(true);
            const response = await api.post('/login_app/justificativa', {
                seq_conselho_reuniao: reuniao.seq_conselho_reuniao,
                justificativa: justificativa
            });

            if(response.data.error === false){
                getReunioes();
                setIsOpen(false);
            }
            setAguarde(false);
        }
    };

    const CardReuniao = (props) => {
        return(<Card key={'reuniao'+props.r.seq_conselho_reuniao} mb='1' >
                <CardBody>
                    <Flex justifyContent='space-between' alignItems='center'>
                        <Flex alignItems='center' onClick={
                            props.r.situacao === 'pode_justificar' ? 
                            () => {setReuniao(props.r); setIsOpen(true);} 
                            : () => {}}>
                            <Box flexDirection='column'>
                                <Text textAlign='center' 
                                      fontSize='md' 
                                      fontWeight='bold' 
                                      lineHeight='1' 
                                      color='red'>
                                        {props.r.dia}
                                </Text>
                                <Text textAlign='center' fontSize='sm' fontWeight='bold'>
                                    {props.r.mes}
                                </Text>
                            </Box>
                            <Text ml='4'>{props.r.titulo}</Text>
                        </Flex>                        
                        {props.r.existe_doc === 't' ? 
                            <Icon boxSize='5' as={IoAttach} marginLeft='auto' marginRight='30px' 
                            onClick={() => {documentos(props.r.seq_conselho_reuniao);}}/>          
                        : ''}             
                        {props.r.situacao === 'presente' ? 
                            <Icon boxSize='5' color='green' as={IoCheckmarkOutline}/> :
                        props.r.situacao === 'pode_justificar' ?
                             <Icon boxSize='5' as={IoChevronForwardOutline} onClick={() => {setReuniao(props.r); setIsOpen(true)}} 
                            /> : 
                        props.r.situacao === 'nao_pode_justificar' ? 
                            <Icon boxSize='5' as={IoBanOutline}/> : 
                        props.r.situacao === 'ja_justificou' ? 
                            <Icon boxSize='6' color='green' as={IoCloseCircleOutline}/> : ''}
                    </Flex>
                </CardBody>
            </Card>);
    };

    const ListaReunioes = (props) => {
        if(props.reunioes.length <= 0){
            return(<Text textAlign='center'>Buscando reuniões...</Text>);
        }
        var ano = props.reunioes[0].ano;

        return(<>
            {props.reunioes.map((r, i) => {
                if(i === 0){
                    return <>
                        <Text fontWeight='bold'>{r.ano}</Text>
                        <CardReuniao r={r}/>
                    </>
                }else{
                    if(r.ano === ano){
                        return <CardReuniao r={r}/>
                    }else{
                        ano = r.ano;
                        return <>
                            <Text fontWeight='bold'>{r.ano}</Text>
                            <CardReuniao r={r}/>
                        </>
                    }
                }
            })}
        </>)

    }

    // const ReuniaoDrawer = (props) => {
    //     return(
    //         <Drawer
    //             isOpen={props.isOpen}
    //             placement='right'
    //             onClose={props.onClose}
    //             size='lg'
    //         >
    //         <DrawerOverlay />
    //         <DrawerContent>
    //             <DrawerCloseButton />
    //             <DrawerHeader>Justificativa</DrawerHeader>

    //             <DrawerBody>
    //                 {reuniao !== null ? <>
    //                 <Text fontWeight='bold'>{props.reuniao.titulo}</Text>
    //                 <Text fontSize='sm' mb='2'>{props.reuniao.hora}</Text>
    //                 <Text mb='2'>Justifique sua ausência:</Text>
    //                 <Textarea mb='2' value={justificativa} onChange={e => setJustificativa(e.target.value)}></Textarea>
    //                 <Button colorScheme='blue' disabled={aguarde} size='lg' float='right'>Enviar</Button></> : ''}
    //             </DrawerBody>
    //         </DrawerContent>
    //         </Drawer>
    //     );
    // };

    return(
        <>
        <Box p='4'>
            <ListaReunioes reunioes={reunioes}/>
        </Box>
        <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={() => setIsOpen(false)}
                size='lg'
            >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Justificativa</DrawerHeader>

                <DrawerBody>
                    {reuniao !== null ? <>
                    <Text fontWeight='bold'>{reuniao.titulo}</Text>
                    <Text fontSize='sm' mb='2'>{reuniao.hora}</Text>
                    <Text mb='2'>Justifique sua ausência:</Text>
                    <Textarea mb='2' value={justificativa} onChange={e => setJustificativa(e.target.value)}></Textarea>
                    <Button colorScheme='red' disabled={aguarde} size='lg' float='right' onClick={() => justificar()}>Enviar</Button></> : ''}
                </DrawerBody>
            </DrawerContent>
            </Drawer>
            <DrawerDomento isOpen={isDrawerDocumentoOpen} onClose={() => setDrawerDocumentoOpen(false)} documentos={Documentos}/>
        {/* <ReuniaoDrawer isOpen={isOpen} onClose={() => setIsOpen(false)} reuniao={reuniao}/> */}
        </>
    );    
};

export default Reunioes;