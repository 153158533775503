import axios from 'axios';

const api = axios.create({
    //baseURL: 'http://localhost:8080/api/index.php'
    //baseURL: 'https://dev.conselho.club/api/index.php'
    baseURL: 'https://cd.internacional.com.br/api/index.php'

});

api.interceptors.request.use(async config => {
    const token = localStorage.getItem('ac');
    if (token) {
        config.headers.Authorization = `bearer ${token}`;
    }
    return config;
});

api.interceptors.response.use( (response) => {
    return response;
}, async (error) => {
    if (401 === error.response.status) {
        console.log('Unauthenticated');
        localStorage.removeItem('ac');
        window.location = '/pwa';
    } else {
        return Promise.reject(error);
    }
});

export default api;