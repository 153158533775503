// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage, deleteToken } from "firebase/messaging";
import api from "./servicos/Api";

const firebaseConfig = {
    apiKey: "AIzaSyAFSU0WPvcb7haOuIB-ftg4VgbVqPJdCuU",
    authDomain: "app-conselho-internacional.firebaseapp.com",
    projectId: "app-conselho-internacional",
    storageBucket: "app-conselho-internacional.appspot.com",
    messagingSenderId: "393892646298",
    appId: "1:393892646298:web:9f9dd536c8f511600c7e23",
    measurementId: "G-6R56XW2XP1"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
const analytics = getAnalytics(firebaseApp);

export const fetchToken = (setTokenFound) => {
    console.log('fetchToken');
    navigator.serviceWorker
    .register("../pwa/firebase-messaging-sw.js")
    .then(function(registration) {
        console.log('registration done');
        getToken(
            messaging, 
            { 
                vapidKey: 'BPi3-7iodlcnC1rtbN5ghSxz6ng6yaixcvkVpHGoW6GMOoaC8GqFhIRGlZjIz3c8nd950PlV_4Hjy64TqnP9Y40',
                serviceWorkerRegistration : registration 
            }
        ).then((currentToken) => {
            console.log('then token '+currentToken);
            if (currentToken) {
                console.log('dt token before');
                if(localStorage.getItem('dt')){
                    console.log('dt token');
                    salvaToken(currentToken);
                    localStorage.setItem('ft', currentToken);
                }
            } else {
                console.log('No registration token available. Request permission to generate one.');

            }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        }); 
    }).catch(function(err) {
        console.log("Service worker registration failed, error:"  , err );
    });   
}  

export const salvaToken = (firebase_token) => {
    api.post(
        '/login_app/set_firebase_token', 
        {firebase_token: firebase_token, device_token: localStorage.getItem('dt')}
    );
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);

    });
});

export const onLogout = () => {
    console.log('entrou no logout');
    deleteToken(messaging).then(() => {
        console.log('Token removido com sucesso!');
      }).catch((error) => {
        console.log('Erro ao remover o token:', error);
      });
};
