import { Box, Center, Flex, Icon, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import { IoHomeOutline, IoPeopleOutline,IoSettingsOutline } from "react-icons/io5";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Home from "../Home";
import Config from "../paginas/Config";
import Notificacoes from "../paginas/Notificacoes";
import Reunioes from "../paginas/Reunioes";
import Votacao from "../paginas/Votacao";
import Header from "./Header";
import { onMessageListener } from '../Firebase';

// onMessageListener().then(payload => {
//   console.log(payload);
// }).catch(err => console.log('failed: ', err));

const LogadoContainer = (props) => {
    const navigate = useNavigate();
    return(
        <Box>
            <Header/>
            <Center>
            <Box mb='100px' w={{base: '100%', sm: '500px'}}>
            <Routes>
                <Route exact path='/' element={localStorage.getItem('ac') ? <Home/> : <Navigate to="/login" />}/>
                <Route exact path='/reunioes' element={localStorage.getItem('ac') ? <Reunioes/> : <Navigate to="/login" />}/>
                <Route exact path='/notificacoes' element={localStorage.getItem('ac') ? <Notificacoes/> : <Navigate to="/login" />}/>
                <Route exact path='/config' element={localStorage.getItem('ac') ? <Config/> : <Navigate to="/login" />}/>
                {/* <Route exact path='/votacao' element={localStorage.getItem('ac') ? <Votacao/> : <Navigate to="/login" />}/> */}
            </Routes>
            </Box>
            </Center>
            <SimpleGrid columns={[3]} w='100%' 
                bg='white' 
                direction='row' 
                justifyContent='space-evenly' 
                position='fixed' 
                bottom='0'
                p='4'
                shadow='md'
                borderTop='1px solid #dddddd'
                >
                <Flex direction='column' justifyContent='center' alignItems='center' cursor='pointer' onClick={() => navigate('/')}>
                    <Icon boxSize='5' as={IoHomeOutline}/>
                    <Text fontSize='sm'>Início</Text>
                </Flex>
                <Flex direction='column' justifyContent='center' alignItems='center' cursor='pointer' onClick={() => navigate('/reunioes')}>
                    <Icon boxSize='5' as={IoPeopleOutline}/>
                    <Text fontSize='sm'>Reuniões</Text>
                </Flex>
                <Flex direction='column' justifyContent='center' alignItems='center' cursor='pointer' onClick={() => navigate('/config')}>
                    <Icon boxSize='5' cursor='pointer' as={IoSettingsOutline}/>
                    <Text fontSize='sm'>Config</Text>
                </Flex>
            </SimpleGrid>
        </Box>
    );
};

export default LogadoContainer;