import { Box, Button, Center, Flex, Image, Input, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import logo from '../assets/login-lg-branco.png';
import bg from '../assets/login-bg.jpg';
import * as ls from "../servicos/Login";
import { useNavigate } from "react-router-dom";
import InputMask from 'react-input-mask';
import { fetchToken } from "../Firebase";
const Login = (props) => {

    var navigate = useNavigate();

    const [cpf, setCpf] = useState('');
    const [codigo, setCodigo] = useState('');

    const [smsEnviado, setSmsEnviado] = useState(false);
    const [celular, setCelular] = useState('');
    const login = async (passo) => {
        if(cpf !== ''){
            var aux = cpf.replace('.','').replace('.','').replace('-','');
            const response = await ls.login(aux, codigo);
            if(response.erro === 0){                
                if(passo === 1 && response.msg === 'SMS'){
                    setSmsEnviado(true);
                    setCelular(response.cel);
                }else if(passo === 2 && response.msg === 'OK'){
                    localStorage.setItem('ac', response.access_token);
                    localStorage.setItem('expires_in', response.expires_in);
                    localStorage.setItem('rt', response.refresh_token);
                    localStorage.setItem('token_type', response.token_type);
                    localStorage.setItem('matricula', response.matricula);
                    localStorage.setItem('tn', response.topico_notificacao);
                    props.setLogado(true);
                    fetchToken();
                    console.log('fetchToken login');
                    navigate('/');
                }else{
                    window.alert('tente novamente');
                }
            }else{
                window.alert(response.msg);
            }
        }
    };

    return(
        <Center>
        <Box w={{base: '100%', sm: '500px'}} backgroundImage={bg} backgroundSize='cover'>
            <Flex h='97vh' direction='column' px='6' justifyContent='center' alignItems='center'>
                <Image h='160px' src={logo} mb='4' alt='logo'/>
                { smsEnviado === false ? <>
                    <Input as={InputMask} autoComplete='off' mask='999.999.999-99' type='text' variant='filled' size='lg' value={cpf} onChange={(e) => setCpf(e.target.value)} _focus={{background: '#ffffff'}} mb='6' colorScheme='white' placeholder='CPF'/>
                    <Button bgColor='red' color='white' h='50px' px='6' mb='6' onClick={() => login(1)}>CONTINUAR</Button>
                    {/* <Text fontSize='sm' as='a' color='white'>Não sou conselheiro</Text>  */}
                    </> : <>
                        <Text fontSize='sm' color='white'>SMS enviado para o número {celular}</Text>
                        <Input type='text' variant='filled' size='lg' value={codigo} onChange={(e) => setCodigo(e.target.value)} _focus={{background: '#ffffff'}} mb='6' colorScheme='white' placeholder='Digite o código do SMS'/>
                        <Button bgColor='red' color='white' h='50px' px='6' mb='6' onClick={() => login(2)}>CONTINUAR</Button>
                    </>
                }
            </Flex>
            <Center>
                <Flex color='white' marginRight='0' marginLeft='auto' px='2' >
                    v1.5.2
                </Flex>     
            </Center>   
        </Box>
        </Center>
    );
};

export default Login;