import api from "./Api";
import axios from "axios";

export const checkAccessToken = async  () => {
    try{
        const response = await api.post('/login_app/check_token');
        console.log(response.data);
        if(response.data.error === false){
            return true;
        }
        else{
            return false;
        }
    }catch(err){
        console.log('erro checkAccessToken');
        return false;
    }
};

export const login = async (cpf, codigo) => {
    try{
        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Basic Z2ZicGE6QEdyM01AdjM=',
                },
            };
        //const response = await axios.post('http://localhost:8080/api/index.php/login_app/authenticate_sms', {    
        const response = await axios.post('https://cd.internacional.com.br/api/index.php/login_app/authenticate_sms', {
        //const response = await axios.post('https://dev.conselho.club/index.php/login_app/authenticate_sms', {
            cpf:cpf,
            idDispositivo: localStorage.getItem('dt'),
            codigo:codigo !== null ? codigo : '',
            desc_dispositivo : navigator.userAgent
        }, config);
        
        return response.data;
    }catch(err){
        console.log('erro login');
        return false;
    }
};