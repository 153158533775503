import React, { useEffect, useState } from "react";
import LogadoContainer from "./LogadoContainer";
import Login from "../paginas/Login";
import { Route, Routes } from "react-router-dom";
import { v1 as uuidv1 } from 'uuid';
import * as ls from "../servicos/Login";

const MainContainer = () => {

    const [logado, setLogado] = useState(false);
    const logout = () => {};

    useEffect(() => {
        const getDeviceToken = () => {
            var dt = localStorage.getItem('dt');
            if(!dt){
                localStorage.setItem('dt', uuidv1());
            }       
        };
    
        const checkAccessToken = () => {
            var ac = localStorage.getItem('ac');
            if(!ac){
                //Não está logado
                setLogado(false);
            }else{
                if(ls.checkAccessToken()){
                    setLogado(true);
                }else{
                    setLogado(false);
                }
            }
        };
        getDeviceToken();
        checkAccessToken();
    }, []);

    

    return(
        <Routes>
          <Route exact path='/login' element={<Login setLogado={setLogado}/>}/>
          <Route path='*' element={<LogadoContainer logado={logado} logout={logout}/>}/> 
        </Routes>
    );
};

export default MainContainer;