import { Box, IconButton, List, ListItem, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { IoLogOutOutline} from "react-icons/io5";
import api from "../servicos/Api";

const Config = () => {
    var navigate = useNavigate();
    const onLogout = () => {
        api.post(
            '/login_app/remove_all_topicos', 
            {
                matricula: localStorage.getItem('matricula')
                , token: localStorage.getItem('ac')
            }
        );
        localStorage.setItem('ac', null); 
        
        navigate('/login');
    };
    
   

    return(
        <Box p='4'>
            <List>
                <ListItem cursor='pointer' display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                    <Text>Sair</Text>
                    <IconButton icon={<IoLogOutOutline/>} borderRadius='50%' onClick={() => {onLogout();}}/>
                </ListItem>               
            </List>
            <Box position="fixed" bottom="100px" right="10px">
                v1.5.2
            </Box>
        </Box>
    );
};

export default Config;