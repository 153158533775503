import React from "react";
import { Flex, Image, Icon } from "@chakra-ui/react";
import {IoNotificationsOutline} from 'react-icons/io5';
import { useNavigate } from "react-router-dom";
import logo from '../assets/logo-conselho-p.png';
import logo_branco from '../assets/logo-conselho-p-white.png';

const Header = () => {
    var navigate = useNavigate();
    return(
        <Flex py='2' pl='2' pr='2' direction='row' justifyContent='space-between' alignItems='center' bg='#e30513'>
            <Image h='50px' src={logo_branco}/>
            {/* <Icon boxSize='5' color='white' cursor='pointer' onClick={() => navigate('/notificacoes')} as={IoNotificationsOutline}/> */}
        </Flex>
    );    
};

export default Header;