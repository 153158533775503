import { Box, Card, CardBody, Flex, Image, Text, Icon, 
    Center, Button, AlertDialog, AlertDialogOverlay, AlertDialogHeader, AlertDialogBody, 
    AlertDialogFooter, AlertDialogContent, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, 
    DrawerHeader, DrawerBody, DrawerFooter, Checkbox, Radio } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "./servicos/Api";
import bg from './assets/login-bg.jpg';
import { IoChatboxOutline, IoCheckmarkOutline, IoPeopleOutline, IoReloadOutline, IoAttach, IoCloudDownloadOutline } from "react-icons/io5";
import { onMessageListener, onBackgroundMessageListener } from './Firebase';
import { color } from "framer-motion";

onMessageListener().then(payload => {
 console.log(payload.notification.title); 
}).catch(err => console.log('failed: ', err));
  
// onBackgroundMessageListener().then(payload => {
//     window.alert(payload.notification.title); 
// }).catch(err => console.log('failed: ', err));

const Home = () => {
    var navigate = useNavigate();
    const [noticias, setNoticias] = useState([]);
    const [dadosReuniao, setDadosReuniao] = useState(null);
    const [Documentos, setDocumentos] = useState(null);
    const [alertMsg, setAlertMsg] = useState({title: 'Info', texto: '', callback: null});

    const [aguarde, setAguarde] = useState({
        presenca: false,
        votacao: false,
        votos: false
    });

    const [voto, setVoto] = useState({
        voto : '',
    })

    const [dispositivo, setDispositivo] = useState({
        seq_conselho_reuniao : '',
        token_dispositivo : localStorage.getItem('dt'),
        desc_dispositivo : navigator.userAgent,
    });

    const [isDrawerNoticiaOpen, setDrawerNoticiaOpen] = useState(false);    
    const [isDrawerComplementoOpen, setDrawerComplementoOpen] = useState(false);    
    const [noticiaSelecionada, setNoticiaSelecionada] = useState(null);
    
    const [isDrawerDocumentoOpen, setDrawerDocumentoOpen] = useState(false);    
    
    const [isDrawerVotacaoOpen, setDrawerVotacaoOpen] = useState(false);

    const [index, setIndex] = useState(0);
    const [questoes, setQuestoes] = useState([]);
    const [dadosVotacao, setDadosVotacao] = useState({});

    const [isAlertOpen, setAlertOpen] = useState(false);
    const cancelRef = React.useRef();

    useEffect(() => {
        getNoticias();
        verificaPresenca();
        atualizaDispositivo();
        validaUser();
    }, []);

    const atualizaDispositivo = async () => {
        const response = await api.post('login_app/atualiza_dispositivo', { data : dispositivo });
    };

    const validaVoto = async (e) => {
        const elements = document.getElementsByClassName('btn-voto');
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.backgroundColor = 'white';
            elements[i].style.color = 'red';
        }
        const element = document.getElementsByClassName('btn-' + e);
        element[0].style.backgroundColor = 'red';
        element[0].style.color = 'white';
        setVoto({...voto, voto: e});
      
    }

    const getNoticias = async () => {
        const response = await api.get('noticias/listar_todas');
        setNoticias(response.data.noticias);
    };

    const validaUser = async () => {
        const response = await api.get('login_app/valida_user');
        if(response.data.valido != "t")
        {
            api.post(
                '/login_app/remove_all_topicos', 
                {
                    matricula: localStorage.getItem('matricula')
                    , token: localStorage.getItem('ac')
                }
            );
            localStorage.setItem('ac', null); 
            
            navigate('/login');
        }
    };

    const verificaPresenca = async () => {
        const response = await api.get('/login_app/reuniao_atual');
        if(response.data.error === false){    
            setDadosReuniao(response.data.data[0]);
            if(response.data.documentos){
                setDocumentos(response.data.documentos);
            }

            
        }
    };

    const downloadDoc = async (seq_conselho_reuniao_documento) => {
        //window.open('http://localhost:8080/api/index.php/login/setToken/'+ localStorage.getItem('dt')+'/'+ seq_conselho_reuniao_documento);
        //window.open('https://dev.conselho.club/api/index.php/login/setToken/'+ localStorage.getItem('dt')+'/'+seq_conselho_reuniao_documento);
        window.open('https://cd.internacional.com.br/api/index.php/login/setToken/'+ localStorage.getItem('dt')+'/'+seq_conselho_reuniao_documento);
    };

    const verificaVotacao = async () => {
        setAguarde({...aguarde, votacao: true});
        const response = await api.get('/login_app/questao_votar?seq_conselho_reuniao='+dadosReuniao.seq_conselho_reuniao);
        console.log(response);
        if(response.data.error === false){
            var aux = [];
            for(var i in response.data.data.opcao){
                if(response.data.data.opcao[i].seq_conselho_reuniao_votacao === response.data.data.questao[index].seq_conselho_reuniao_votacao){
                    aux.push(response.data.data.opcao[i]);
                }
            }
            setDadosVotacao({
                questao: response.data.data.questao[index],
                titulo_complemento: response.data.data.questao[index].titulo_complemento,
                complemento: response.data.data.questao[index].complemento,
                opcoes: aux
            });
           
            setDrawerVotacaoOpen(true);
        }else{
            setAlertMsg({title: 'Info', texto: response.data.msg});
            setAlertOpen(true);
        }
        setAguarde({...aguarde, votacao: false});
    };

    const registraVoto = async (voto) => {
        setAguarde({...aguarde, votos: true});
        if(voto == ''){
            setAlertMsg({title: 'Ops', texto: 'Você deve selecionar uma das opções', callback: () => setDrawerVotacaoOpen(true)});
            setAlertOpen(true);
        } else {
            const response = await api.post('/login_app/questao_voto', {
                seq_conselho_reuniao: dadosReuniao.seq_conselho_reuniao,
                seq_conselho_reuniao_votacao: dadosVotacao.questao.seq_conselho_reuniao_votacao,
                seq_conselho_reuniao_presenca: dadosReuniao.seq_conselho_reuniao_presenca,
                voto: voto
            });
    
            if(response.data.error === false){

                setAlertMsg({title: '', texto: 'Votação Realizada', callback: () => setDrawerVotacaoOpen(false)});
                setAlertOpen(true);
                setVoto({...voto, voto: ''});

            }else{
                setAlertMsg({title: 'Ops', texto: response.data.msg, callback: () => setDrawerVotacaoOpen(false)});
                setAlertOpen(true);
            }
            setAguarde({...aguarde, votos: false});
        }


    };

    const onAlertClose = () => {
        setAlertOpen(false);
        if(alertMsg.callback){
            alertMsg.callback();
        }
    };

    const DrawerNoticia = (props) => {
        if(props.noticia){
        return(<Drawer
            isOpen={props.isOpen}
            placement='right'
            onClose={props.onClose}
            size='lg'
        >
            <DrawerOverlay />
            <DrawerContent>
            {/* <DrawerCloseButton /> */}
            <DrawerHeader>{props.noticia.titulo}</DrawerHeader>

            <DrawerBody>
                {props.noticia.imagem_boa ? <Image borderRadius='5px' mb='4' src={props.noticia.imagem_boa}/> : ''}
                <Box mb='4' dangerouslySetInnerHTML={{__html: props.noticia.corpo}}></Box>
            </DrawerBody>
            
            <DrawerFooter style={{backgroundColor:'red', color: 'white', width: '100%', border: 'none', justifyContent: 'center'}} onClick={() => setDrawerNoticiaOpen(false)}>
                Fechar Notícia
            </DrawerFooter>
            </DrawerContent>

        </Drawer>);}
        else
            return(<></>);
    };

    const DrawerDomento = (props) => {
        if(props.documentos){
        return (
        <Drawer isOpen={isDrawerDocumentoOpen} placement='right' onClose={() => setDrawerDocumentoOpen(false)} size='lg'>
            <DrawerOverlay />
            <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Documentos da Reunião {dadosReuniao.titulo}</DrawerHeader>

            <DrawerBody>
                <>
                {props.documentos.map(doc => {
                    return (
                            <Card mb='2' onClick={()=>{downloadDoc(doc.seq_conselho_reuniao_documento)}}>
                                <CardBody>
                                    <Flex justifyContent='space-between' alignItems='center'>
                                        <Flex alignItems='center'>
                                            <Text ml='4' fontWeight='700'>{doc.nome}</Text>
                                        </Flex>                        
                                        <Icon boxSize='6' color='red' as={IoCloudDownloadOutline}/>
                                    </Flex>
                                </CardBody>
                            </Card>
                        )
                    })
                }
                </> 
            </DrawerBody>
            </DrawerContent>
        </Drawer> 
        ) } else {
            return (<></>)
        }
    };

    return(
        <>
        {dadosReuniao ?
        <Box backgroundImage={bg} color='white' p='6' mb='4' backgroundSize='cover'>
            { Documentos.length > 0
            ? 
            <Flex justifyContent='right' alignItems='right'>
                <Icon boxSize='10' padding="5px" as={IoAttach} marginRight='0' marginLeft='auto' color='white' onClick={() => {setDrawerDocumentoOpen(true)}} />
            </Flex>
            : ''}
            <Flex direction='column' justifyContent='center' alignItems='center'>
                <Icon boxSize='8' as={IoPeopleOutline}/>
                <Text fontWeight='bold' lineHeight='1'>{dadosReuniao.titulo}</Text>
                <Text fontSize='sm' mb='4'>{dadosReuniao.hora}</Text>
                {dadosReuniao.presente === 't' ? 
                <Button bg='white' mb='4' w='210px' onClick={()=>verificaPresenca()} color='black'><Icon boxSize='5' color='black' as={IoCheckmarkOutline} mr='1'/> Presença confirmada</Button> : 
                <Button bg='white' mb='4' w='210px' onClick={()=>verificaPresenca()} color='black'><Icon boxSize='5' as={IoReloadOutline} mr='1'/> Verificar presença</Button>}
                {dadosReuniao.em_andamento === 't' && dadosReuniao.presente === 't' ?
                <Button colorScheme='red' w='210px' disabled={aguarde.votacao} onClick={() => {verificaVotacao(); setVoto({...voto, voto: ''})}}>
                    <Icon boxSize='5' as={IoChatboxOutline} mr='1'/> {aguarde.votacao ? 'Aguarde...' : 'Votação'}
                </Button> : 
                ''
                }
            </Flex>
        </Box> : ''}
        <Box px='4' mt='2'>
            {noticias.map(n =>
                <Card mb='2' cursor='pointer' onClick={() => {setNoticiaSelecionada(n); setDrawerNoticiaOpen(true)}}>
                    <CardBody>
                        <Flex direction='row' justifyContent='space-between' alignItems='center'>
                            <Box pr='2'>
                                <Text fontSize='sm' color='red'>{n.data_inicio}</Text>
                                <Text lineHeight='1.2'>{n.titulo}</Text>                                
                            </Box>
                            <Image borderRadius='5px' w='100px' src={n.imagem_boa}/>
                        </Flex>
                    </CardBody>
                </Card>
            )}
        </Box>
        <AlertDialog
            isOpen={isAlertOpen}
            leastDestructiveRef={cancelRef}
            onClose={onAlertClose}
            closeOnEsc={false}
            closeOnOverlayClick={false}
        >
            <AlertDialogOverlay>
            <AlertDialogContent>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                    {alertMsg.title}
                </AlertDialogHeader>

                <AlertDialogBody>
                    {alertMsg.texto}
                </AlertDialogBody>

                <AlertDialogFooter>
                <Button colorScheme='red' onClick={onAlertClose} ml={3}>
                    Ok
                </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>

        <DrawerNoticia isOpen={isDrawerNoticiaOpen} onClose={() => setDrawerNoticiaOpen(false)} noticia={noticiaSelecionada}/>
        <Drawer
            isOpen={isDrawerVotacaoOpen}
            placement='right'
            onClose={() => {setDrawerVotacaoOpen(false)}}
            size='lg'
        >
            <DrawerOverlay />
            <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Votação</DrawerHeader>

            <DrawerBody>
                {dadosVotacao ? <>
                <Text fontSize='sm' fontWeight='bold' mb='6'>Leia com atenção e, após, escolha uma das opções e clique em confirmar.</Text>
                <Text mb='6'>{dadosVotacao.questao ? dadosVotacao.questao.tema : ''}</Text>
                <Text mb='6' style={{'color':'red','textAlign':'right'}} onClick={()=> setDrawerComplementoOpen(true)}>{(dadosVotacao.complemento ? `` + dadosVotacao.titulo_complemento + `` : '')}</Text>
                <Flex direction='column' justifyContent='center' alignItems='center'>
                    {dadosVotacao.opcoes ? 
                    dadosVotacao.opcoes.map(o => 
                                    <Button mb='4' w='100%'
                                            border="solid 1px red"
                                            backgroundColor={'white'}
                                            textColor="red"
                                            className={'btn-voto btn-'+o.seq_conselho_reuniao_votacao_resposta}
                                            value={o.seq_conselho_reuniao_votacao_resposta}
                                            disabled={aguarde.votos}
                                            onClick={() => validaVoto(o.seq_conselho_reuniao_votacao_resposta)} 
                                            size='lg'>{o.resposta}
                                            </Button>
                                        )
                        : ''}
                    { dadosVotacao.opcoes 
                    ? <Button colorScheme='red' mb='4' w='100%'
                        disabled={aguarde.votos}
                        onClick={() => registraVoto(voto.voto)} 
                        size='lg'>
                            CONFIRMAR VOTO
                    </Button>
                    : '' }
                </Flex>
                </> : ''}
            </DrawerBody>
            </DrawerContent>
        </Drawer>
        <Drawer
            isOpen={isDrawerComplementoOpen}
            placement='right'
            onClose={() => {setDrawerComplementoOpen(false)}}
            size='lg'>
            <DrawerOverlay />
            <DrawerContent>
            <DrawerCloseButton />
            <DrawerBody>
                <Flex direction='column' justifyContent='left' alignItems='left'>  
                <Box mb='4' dangerouslySetInnerHTML={{__html: dadosVotacao.complemento}}></Box>
                </Flex>
            </DrawerBody>
            <DrawerFooter style={{backgroundColor:'red', color: 'white', width: '100%', border: 'none', justifyContent: 'center'}} onClick={() => setDrawerComplementoOpen(false)}>
                Fechar Informação
            </DrawerFooter>
            </DrawerContent>
        </Drawer>
        <DrawerDomento isOpen={isDrawerDocumentoOpen} onClose={() => setDrawerDocumentoOpen(false)} documentos={Documentos}/>

       
        </>


    );
};

export default Home;